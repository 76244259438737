import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, americanExpress, defaultCard, masterCard, visa } from "./assets";
import { FormikProps, FormikValues } from "formik";
import { getStorageData } from "../../../framework/src/Utilities";
import { cleanCardNumber } from "../../../components/src/MuiCustomComponents";
const skyCards = [
  {
    type:"Debit",
    remaining:"$12,300",
  },
  {
    type:"25 Hour",
    remaining:"12Hrs & 32Mins",
    aircraft:"Super Midsize Jet",
  }
]
const debitCards = [
  {
    type:"debit",
    cardholder_name:"Leslie Alexander",
    card_number:"5678"
  },
  {
    type:"Visa",
    cardholder_name:"Leslie Alexander",
    card_number:"5678"
  }
]

const cards=[
  {
      "id": 288,
      "type": "card",
      "attributes": {
          "cards": {
              "id":288,
              "card_holder_name": "Mohan S",
              "card_number": "5439",
              "expiry_date": "12/24",
              "card_type": "Visa",
              "zipcode": "600053",
              "country_code": 5,
              "street_address": "54/6",
              "city": "Chennai",
              "state": "Tamilnadu",
              "country": "India",
              "country_code_string": "US"
          },
          "primary_method": false
      }
  }
  
]

interface Card{
  id:number,
  cardNumber:string,
  cardholderName:string,
  expiryDate:string,
  securityCode:string,
  streetAddress:string,
  zipCode:string,
  city:string,
  state:string,
  country:{name:string}
}

export interface CardDetails{
  id:number,
  type:string,
  attributes: {
  cards:{
    id: number,
    card_type: string,
    card_number: string,
    card_holder_name: string,
    country_code: number,
    street_address: string,
    zipcode: string,
    city: string,
    state: string,
    country: string,
    country_code_string: string,
    expiry_date: string,
      }
  primary_method: boolean
      }
  
}

interface ProfileDataType  {
    email:string | null,
    account_id: null | number,
    phoneNumber:string,
}

interface DynamicStringObject  {
  [key: string]: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  page: string,
  showPaymentModal: boolean,
  action: string,
  isStatusModalVisible:boolean,
  cardIndex:number,
  focusedLabel: null | string
  profileData: ProfileDataType
  isSureModalOpen:boolean
  cards:CardDetails[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfpaymentgateway33Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cardFormik:FormikProps<FormikValues>|null = null;
  createNewCardAPICallId:string = "";
  updateCardDetailsAPICallId:string = "";
  getUserProfileCallApiId:string = '';
  getUserPaymentMethodsApiId:string="";
  makePrimaryMethodAPICallId:string=""
  deleteCardAPICallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      page:"",
      showPaymentModal:false,
      action:"",
      isStatusModalVisible:false,
      cardIndex:-1,
      focusedLabel:null,
      profileData:{
        account_id:559,
        email:"mohandante@gmail.com",
        phoneNumber:"9176950536"
      },
      isSureModalOpen:false,
      cards:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson && responseJson.data){
        
        switch (apiRequestCallId) {

          case this.getUserProfileCallApiId:
            this.handleUserProfileSuccessApiResponse(responseJson.data);
            break;

          case this.getUserPaymentMethodsApiId:
            this.setState({cards:cards})
            break;

          case this.updateCardDetailsAPICallId:
            this.handleCreateOrUpdateCardResponse()
            break;
          
          case this.createNewCardAPICallId:
            this.handleCreateOrUpdateCardResponse()
            break;  
            

          default:
            break;
        }

      }
      else if(responseJson.message){
        this.handleMessageResponse(apiRequestCallId,responseJson)
      }
      else{

        if(apiRequestCallId === this.getUserPaymentMethodsApiId){
          this.setState({cards:cards})
        }
        else{
          this.handleErrorResponse(apiRequestCallId,responseJson)
        }

      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    const {location:{pathname}} = window
    this.getUserProfileDetails()
    this.getUserPaymentMethods()
    this.setState({page:pathname})
  }
  handleCardProvider = (card_type: string) => {
    switch (card_type) {
      case 'MasterCard':
        return masterCard;
      case 'Visa':
        return visa;
      case 'Amex':
        return americanExpress;
      default:
        return defaultCard;
    }

  }

  handleStatusVisible=(isOpen:boolean,)=>{
    this.setState({isStatusModalVisible:isOpen,action:""})
  }

  handleFocus=(label:string | null)=>{
    this.setState({focusedLabel:label})
  }

  openPaymentModal = (action: string, cardIndex = -1) => {
    if (action === "edit" && cardIndex < 0) {
      cardIndex = this.state.cardIndex
    }
    this.setState({ showPaymentModal: true, action: action, cardIndex: cardIndex })
  }

  closePaymentModal = () => {
    
    this.setState({ showPaymentModal: false, cardIndex: -1, focusedLabel: null, action: "",isSureModalOpen:false })
  }

  handleCreateNewCard =async (values:Card)=>{
    const {profileData,action} = this.state
     
    let cardDetails = {
      account_id: profileData.account_id,
      data: {
        attributes: {
          card_type: "credit",
          card_number: cleanCardNumber(values.cardNumber),
          card_holder_name: values.cardholderName,
          expiry_date: values.expiryDate,
          security_code: values.securityCode,
          email: profileData.email,
          phone_number: profileData.phoneNumber,
          street_address: values.streetAddress,
          zipcode: values.zipCode,
          city: values.city,
          state: values.state,
          country: values.country.name
        }
      }
    }
  
    const apiUrl = action !== "add" ? `${configJSON.addOrEditCardAPIEndpoint}/${values.id}` : configJSON.addOrEditCardAPIEndpoint
    const apiMethod = action !== "add" ? configJSON.httpPutMethod : configJSON.exampleAPiMethod
  
    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
      "Authenticate-User":true
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    if(action === "add"){
      this.createNewCardAPICallId = requestMessage.messageId
    }
    else{
      this.updateCardDetailsAPICallId = requestMessage.messageId
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(cardDetails)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  handleSureModalOpen=(isOpen:boolean,cardId=-1)=>{
    this.setState({isSureModalOpen:isOpen,cardIndex:cardId});
  }

  handleCloseSureModal = ()=>{
    this.setState({isSureModalOpen:false,cardIndex:-1})
  }

  getUserProfileDetails=async()=>{

    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      
    this.getUserProfileCallApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserPaymentMethods=async()=>{

    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      
    this.getUserPaymentMethodsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPaymentMethodsApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUserProfileSuccessApiResponse=(responseJson:{attributes:any})=>{
    const {attributes:{email,account_id, phone_number}} = responseJson;
    this.setState({profileData:{account_id,email,phoneNumber:phone_number}})
  }

  handleMakePrimaryMethod = async(cardId:number)=>{

    const cardDetails = {
      type:"card",
      id:cardId,
      account_id:this.state.profileData.account_id
    }
    
    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.makePrimaryMethodAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.makePrimaryPaymentMethodAPIEndpoint}`    
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(cardDetails)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  
  
  }

  handleDeleteCard =async () => {
    const{cards,cardIndex} = this.state
    
    const header = {
      token: await getStorageData("token")
    };
  
    const deleteCardMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteCardAPICallId = deleteCardMsg.messageId;
  
    deleteCardMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCardAPIEndpoint}/${cards[cardIndex].id}`
    );
  
    deleteCardMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    deleteCardMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePassengerMethod
    );
  
    runEngine.sendMessage(deleteCardMsg.id, deleteCardMsg);
  }

  getCardDetails = ()=>{ 
    const {cards,action,cardIndex} = this.state
    if(!action || action==="add"){return}
    const card = cards[cardIndex]
    
    const {cards:{card_holder_name,card_number,expiry_date,street_address,city,state,zipcode,country},primary_method} = card.attributes
    const cardDetails = {
    primaryMethod:primary_method,
    cardNumber:card_number,
    cardholderName : card_holder_name,
    expiryDate:expiry_date,
    streetAddress:street_address,
    city:city,
    state:state,
    zipCode:zipcode,
    country:{name:country},
    id:card.id,
  
    }
  
    return cardDetails
  }

  handleCreateOrUpdateCardResponse = ()=>{
    this.setState({showPaymentModal:false,focusedLabel:"",cardIndex:-1,isStatusModalVisible:true})
    this.getUserPaymentMethods()

  }

  handleMessageResponse = (apiRequestCallId: string, responseJson: { message: DynamicStringObject }) => {


    if (apiRequestCallId === this.createNewCardAPICallId) {
      if (responseJson.message.error_message === "Invalid card number") {
        (this.cardFormik as FormikProps<FormikValues>).setFieldError("cardNumber", "Invalid card number")

      }
    }
    else if (apiRequestCallId === this.makePrimaryMethodAPICallId) {
      this.handleTriggerAlertMessage("Card marked as primary method")
      this.getUserPaymentMethods()


    }
    else if (apiRequestCallId === this.deleteCardAPICallId) {
      this.closePaymentModal()
    }
  }

  handleTriggerAlertMessage=(toastMessage:string)=>{
    const messageTriger = new Message(getName(MessageEnum.AlertMessage));
    messageTriger.addData(getName(MessageEnum.AlertBodyMessage), toastMessage);
    this.send(messageTriger);
  }

  handleErrorResponse = (apiRequestCallId:string,responseJson:{errors:DynamicStringObject[]})=>{
    if (responseJson.errors[0].card_number) {
      (this.cardFormik as FormikProps<FormikValues>).setFieldError("cardNumber", responseJson.errors[0].card_number)
    }
    if (responseJson.errors[0].expiry_date || responseJson.errors[1]?.expiry_date) {
      (this.cardFormik as FormikProps<FormikValues>).setFieldError("expiryDate", "Expired Card, Please use valid card")
    }

  }
  
  // Customizable Area End
}
