import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

const cataloguEmptyForm = {
  aircraft_name : null,
  aircraft_manufacturer : null,
  aircraft_passenger : null,
  luggage_capacity : null,
  year_of_manufacturer : null,
  year_of_refurb : null,
  aircraft_category : 'none',
  aircarft_images:[],
  main_image:null,
  aircraft_id:null,
  aircraft_number:null,
  deleteFileArray:[]
}

import React from "react"
import { FormikProps } from "formik";
import {getStorageData} from '../../../framework/src/Utilities';
import { handleTriggerAlertMessage } from "../../../components/src/Navigator";
export interface CatalogueFormType {
  aircraft_name : string | null
  aircraft_manufacturer : string | null
  aircraft_category : string | null
  aircraft_passenger : number | null
  luggage_capacity : number | null
  year_of_manufacturer : number | null
  year_of_refurb : number | null
  aircarft_images:File[],
  main_image:null,
  aircraft_id: null |string,
  aircraft_number : null | string,
  deleteFileArray : number[]
}

interface CategoryAircraftType{
  label: string,
  value: string
}

interface ResType{
  meta:{message:string}
}

export interface FileResType{
  id : number
  file_name : string
  file_url : string
}

export interface EachAircarft{
  id: string
  type: string
  attributes: {
    aircraft_number: string
    name: string
    manufacturer: string
    category: string
    passengers: number
    luggage_capacity: string
    year_of_manufacture: string
    year_of_refurb: string
    created_at: string
    main_image: null
    aircraft_images: {
      files: Array<unknown>
    }
  }
}

interface CatalogueResType{
  total_pages: number,
	data: {data : EachAircarft[]}
}

export interface Attributes{
  id:number
  aircraft_name:string
  aircraft_id:string
  date_created:string
  aircarft_category:string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  isTableMode:boolean
  isViewMode:boolean
  isEditMode:boolean
  isAddMode:boolean
  catalogueInitialValue:CatalogueFormType
  focusLabel:string
  gridArray:null[]
  catalogueTableData : EachAircarft[]
  searchQuery:string
  pageNumber: undefined | number
  totalPage: undefined | number
  isLoading: boolean
  sortColumn:null | string
  sort_type:'asc' | 'desc',
  aircraftCatData : CategoryAircraftType[],
  isDeleteCatalogueModalOpen:boolean,
  isFilterModalOpen:boolean,
  filterArray : string[],
  appliedCategoryFilter : string[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  catalogueFormik:null | FormikProps<unknown> = null;
  fileInputRefs:React.RefObject<HTMLInputElement>[] = Array.from({ length: 9 }, () => React.createRef());
  mainImageRef:React.RefObject<HTMLInputElement> = React.createRef();
  getCatalogueListCallApiId:string="";
  getAircraftCatApiCallId:string='';
  addEditAircraftCatalogueApiId:string="";
  deleteAircraftApiId:string="";
  timeOut : NodeJS.Timeout | null = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      isTableMode:true,
      isViewMode:false,
      isEditMode:false,
      isAddMode:false,
      catalogueInitialValue:cataloguEmptyForm,
      focusLabel:"",
      gridArray:Array(8).fill(null),
      catalogueTableData: [],
      searchQuery : "",
      pageNumber : 1,
      totalPage : undefined,
      isLoading : true,
      sortColumn : "aircraft_id",
      sort_type : 'asc',
      aircraftCatData:[],
      isDeleteCatalogueModalOpen : false,
      isFilterModalOpen:false,
      filterArray : [],
      appliedCategoryFilter: ['Any']
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getCatalogueTableData();
    this.getAircarftCat();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    this.handleNewResponse(message);
    // Customizable Area End
  }

  // Customizable Area Start

  handleFocusLable=(focusLabel="")=>{
    this.setState({focusLabel})
  }

  handleFormsAndTable=(isViewMode=this.state.isViewMode,isEditMode=this.state.isEditMode,isAddMode=this.state.isAddMode,isTableMode=this.state.isAddMode)=>{
    this.setState({isViewMode,isEditMode,isAddMode,isTableMode})
  }

  handleBackSteps=()=>{
    const {isEditMode} = this.state;
    if (!isEditMode) this.handleFormsAndTable(false,false,false,true)
    else this.handleFormsAndTable(true,false,false,false)
  }

  handleUploadClick = (fileRef:React.RefObject<HTMLInputElement>) => {
    fileRef?.current?.click();
    // this.fileInputRefs[imgIndex]?.current?.click();
  };

  handleImageSrc=(imageInfo:File | FileResType)=>{
    if(imageInfo instanceof Blob) return URL.createObjectURL(imageInfo);
    return (imageInfo as unknown as FileResType).file_url;
  }
  handleAppliedFilter=(appliedFilter:string[])=>{
    this.setState({appliedCategoryFilter:appliedFilter,isFilterModalOpen:false});
    this.getCatalogueTableData(undefined,1,undefined,undefined,appliedFilter);
  }

  getCatalogueTableData= async(search=this.state.searchQuery,pageNumber=this.state.pageNumber,sortColumn=this.state.sortColumn,sort_type=this.state.sort_type,appliedCategoryFilter=this.state.appliedCategoryFilter)=>{
    this.setState({isLoading:true});
    const filterParam = !appliedCategoryFilter.includes('Any') ? `categories=${JSON.stringify(appliedCategoryFilter)}` : "";
    const queryParams = `?page_number=${pageNumber}&number_of_items_per_page=${10}&query=${search}&sort=${sortColumn}&sort_type=${sort_type}&${filterParam}`
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      
    this.getCatalogueListCallApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCatalogueListEndPoint + queryParams
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCatalogueSeachChange=(event:React.ChangeEvent<HTMLInputElement>)=>{
    const searchQuery = event.target.value;
    this.setState({searchQuery});

    const timer = 1500;
    if(this.timeOut){
        clearTimeout(this.timeOut);
    }
    this.timeOut = setTimeout(()=>{
          this.setState({pageNumber:1});
          this.getCatalogueTableData(searchQuery);
    },timer)
  }

  handleSort=(clickedSortColumn:string)=>{
    const {sortColumn,sort_type} = this.state;
    let sortType: "asc" | "desc" = "asc";
    if(sortColumn === clickedSortColumn) sortType = sort_type === 'asc' ? 'desc' : 'asc';
    this.setState({sortColumn:clickedSortColumn,pageNumber:1,sort_type:sortType});
    this.getCatalogueTableData(undefined,undefined,clickedSortColumn,sortType);
  }

  handleCurrentPage=(currentPage:number)=>{
    this.setState({pageNumber:currentPage});
    this.getCatalogueTableData(undefined,currentPage);
}

  handleNewResponse=(message : Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId){
        case this.getCatalogueListCallApiId:
          this.handleListResponse(responseJson);
          break;
        case this.getAircraftCatApiCallId:
          this.handleCategoryResponse(responseJson);
          break;
        case this.addEditAircraftCatalogueApiId:
          this.handleAddEditAircraftResponse(responseJson);
          break;
        case this.deleteAircraftApiId:
          this.handleDeleteCatResponse(responseJson)  ;
          break;
        default:
          break;
      }
    }
  }

  handleCategoryResponse=(responseJson:{categories: CategoryAircraftType[]})=>{
    if(responseJson.categories){
      let filterList = responseJson.categories.map(({label})=>label)
      this.setState({aircraftCatData:responseJson.categories,filterArray:['Any',...filterList],appliedCategoryFilter: ['Any',...filterList]});
    } 
  }

  handleListResponse=(responseJson:CatalogueResType)=>{
      if(responseJson.data){
        const {data:{data},total_pages} = responseJson;
        this.setState({catalogueTableData:data,totalPage:total_pages});
      }else{
        handleTriggerAlertMessage(this.send,'Something Went Wrong','error');
      }
      this.setState({isLoading:false});
  }

  getAircarftCat=()=>{
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAircraftCatApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAircraftCategoriesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCatalogueFormSubmit=async(values:CatalogueFormType)=>{
    let formData = this.handleFormData(values);
    const {isEditMode,catalogueInitialValue:{aircraft_id}} = this.state;
    const method = isEditMode ? configJSON.updateMethod : configJSON.postMethod;
    const endPoint = isEditMode ? `/${aircraft_id}` : ''
    const headers = {
      token : await getStorageData('token')
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addEditAircraftCatalogueApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCatalogueListEndPoint + endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFormData=(values:CatalogueFormType)=>{
    const form_data = new FormData();
    const {aircarft_images,aircraft_category,aircraft_manufacturer,aircraft_name,aircraft_passenger,luggage_capacity,main_image,year_of_manufacturer,year_of_refurb,deleteFileArray} = values;

    form_data.append('data[name]',aircraft_name as string);
    form_data.append('data[category]',aircraft_category as string);
    form_data.append('data[passengers]',aircraft_passenger as unknown as string);
    form_data.append('data[luggage_capacity]',luggage_capacity as unknown as string);
    form_data.append('data[manufacturer]',aircraft_manufacturer as unknown as string);
    form_data.append('data[year_of_manufacture]',year_of_manufacturer as unknown as string);
    form_data.append('data[year_of_refurb]',year_of_refurb as unknown as string);
    (main_image as unknown as Blob) instanceof File && form_data.append('data[main_image]',main_image as unknown as Blob);
    aircarft_images.forEach((eachImg)=>eachImg instanceof File && form_data.append('data[aircraft_images][]',eachImg));
    deleteFileArray.forEach((delId:number) => form_data.append('data[aircraft_image_ids][]',delId as unknown as string));
    return form_data;
}

handleAddEditAircraftResponse=(responseJson:ResType & {data : EachAircarft})=>{
  if(responseJson.meta){
    const {meta:{message}} = responseJson;
    const {catalogueTableData} = this.state;

    if(message === 'Aircraft is created'){
      this.handleFormsAndTable(undefined,undefined,undefined,true);
      this.setState({catalogueTableData:[responseJson.data,...catalogueTableData]}); 
    } else{
      this.handleActiveCatalogueData(responseJson.data);
      this.handleFormsAndTable(true,false,false,false);
    }
    handleTriggerAlertMessage(this.send,message);
    return;  
  }
  handleTriggerAlertMessage(this.send,'Something went wrong','error');
}

handleViewDetailsClick=(catData:EachAircarft)=>{
  this.handleActiveCatalogueData(catData);
  this.handleFormsAndTable(true,false,false,false)
}

handleActiveCatalogueData=(catData:EachAircarft)=>{
  const {attributes:{aircraft_images,aircraft_number,category,luggage_capacity,main_image,manufacturer,name,passengers,year_of_manufacture,year_of_refurb},id} = catData;

  this.setState({
    catalogueInitialValue : {
      aircarft_images:aircraft_images.files as unknown as File[],
      aircraft_category:category,
      aircraft_manufacturer:manufacturer,
      aircraft_name:name,
      aircraft_passenger:passengers,
      luggage_capacity:luggage_capacity as unknown as number,
      main_image,
      year_of_manufacturer:year_of_manufacture as unknown as number,
      year_of_refurb:year_of_refurb as unknown as number,
      aircraft_id : id,
      aircraft_number : aircraft_number,
      deleteFileArray:[]
    }
  })
}

handleResetForm=()=>{
  this.setState({catalogueInitialValue:cataloguEmptyForm})
}

handleAddNewAircraft=()=>{
  this.handleFormsAndTable(false,false,true,false);
  this.handleResetForm();
}

handleDeleteModalOpen=(isOpen=false)=>{
  this.setState({isDeleteCatalogueModalOpen:isOpen});
}

handleIsFilterModalOpen=(isOpen=false)=>{
  this.setState({isFilterModalOpen:isOpen});
}

deleteAircarftCat=async()=>{
  const {catalogueInitialValue:{aircraft_id}} = this.state;
  const header = {
    "token": await getStorageData('token')
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteAircraftApiId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getCatalogueListEndPoint + `/${aircraft_id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleDeleteCatResponse=(responseJson:{message:string})=>{
  if(responseJson.message){
    this.handleFilterAndDeleteCatalogue();
    handleTriggerAlertMessage(this.send,responseJson.message);
    this.handleDeleteModalOpen(false);
    this.handleFormsAndTable(false,false,false,true);
  }
}

handleFilterAndDeleteCatalogue=()=>{
  const {catalogueInitialValue:{aircraft_id},catalogueTableData} = this.state;
  const filteredData = catalogueTableData.filter((catalogue)=> catalogue.id !== aircraft_id);
  this.setState({catalogueTableData:[...filteredData]});
}

handleCheckUncheck = (label: string, appliedFilter: string[]) => {
  const { filterArray } = this.state;
  if (label === 'Any') {
    return appliedFilter.includes(label) ? [] : ['Any', ...filterArray];
  }
  let arr = appliedFilter.includes(label)
    ? appliedFilter.filter(category => category !== label && category !== 'Any') 
    : [...appliedFilter, label];
    if(arr.length === filterArray.length-1) arr.push('Any');
    return arr;
};

  // Customizable Area End
}
