import React from "react";

// Customizable Area Start
import {
  Box,
  FormControlLabel,
  InputAdornment,
  IconButton,
  CircularProgress,
  TextFieldProps,
} from "@material-ui/core";
import { CustomFormTextField, CustomLabel,CustomFormCheckbox,ForgotPasswordTypeButton,FormSubmitButton, CustomDepartueAiportAutoCompplete, CustomStyledPopper } from "../../../components/src/MuiCustomComponents";
import "./EmailAccountRegistration.css";
import {theme as colors} from "../../../components/src/utils";
import {Visibility, VisibilityOff,ClearOutlined, CheckRounded, SearchOutlined, RoomOutlined} from "@material-ui/icons";
import { Formik, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address*')
    .required('Email is required*'),
  password: Yup.string()
    .required('Password is required*')
    .required('Password is required*')
    .test(
        'no-spaces',
        'Invalid password: it contains spaces',
        value => {
            return !/\s/.test(value);
        }
    )
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase character')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
    .matches(/\d/, 'Password must contain at least one number'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Confirm Password must match*')
  .required('Confirm Password is required*'),
  terms_and_conditions_accepted: Yup.boolean()
  .oneOf([true], 'Please agree to Privacy and Terms Policies to register')
  .required('Please agree to Privacy and Terms Policies to register'),
});
type handleChange = (eventOrPath: React.ChangeEvent<unknown> | string) => void;
type valuesType = {email:string,password:string,password_confirmation:string,terms_and_conditions_accepted:boolean} 


const options = [{name:"Alabama, Birmingham - KBHM"},{name:"Alabama, Birmingham - KBHM"},{name:"Alabama, Birmingham - KBHM"},{name:"Alabama, Birmingham - KBHM"},{name:"Alabama, Birmingham - KBHM"},{name:"Alabama, Birmingham - KBHM"}];


const phoneValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string().required('Phone number is required*'),
  fullName: Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .matches(/^[a-zA-Z\s'-]+$/, "Invalid Full Name*")
    .min(1, 'Name cannot be empty*')
    .required('Full name is required*')
    .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
    preferredAirport: Yup.object().shape({
      name: Yup.string()
        .required("Preffered Airport is required*")
        .min(1, "Preffered Airport can't be empty")
    })
    .nullable()
    .required('Preffered Airport is required*')
});
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  renderCreateProfileForm = () => {
    const { isAutcompleteMenuOpen, isDataLoading,focusLabel } = this.state;
  
    return (
      <Box className="regsiter_create_profile_form">
        <Box className={"register_form_head"}>Create a profile</Box>
        <Box className={"register_create_profile_sub_heading"}>
          Add your profile details
        </Box>
  
          <Formik
            initialValues={{ phone: null,phoneNumber:"",countryCode:"",fullName:"",preferredAirport:null }}
            onSubmit={(values) => {
              this.createNewProfile(values);
            }}
            innerRef={(formikProps) => {
              this.formikMethods = formikProps;
            }}
            validationSchema={phoneValidationSchema}
            validateOnChange
            validateOnBlur
          >
            {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          handleChange
        }) => ( <form style={{marginBottom:"0"}}>
          <Box className={"register_create_profile_form_parent"}>
    
            <Box>
              <CustomLabel isFocused={focusLabel === 'full_name'}>Full Name</CustomLabel>
              <CustomFormTextField
                className={
                  touched.fullName && errors.fullName ? "register_border_error" : ""
                }
                initialBorderColor='white'
                onFocus={()=>this.handleLabelFocus('full_name')}
                onBlur={()=>this.handleLabelFocus()}
                isFocusedTypeInput={true}
                variant='outlined'
                id="register_full_name"
                name="fullName"
                onChange={handleChange}
                size="medium"
                placeholder="E.g *John Smith*"
                fullWidth
              />
              {touched.fullName && (
          <Box className="login_valid_error_new">
            {errors.fullName}
          </Box>
        )}
            </Box>
    
            <Box>
              <CustomLabel isFocused={focusLabel === 'phone'}>Phone Number</CustomLabel>
              <PhoneInput
                inputClass={`regsiter_phone_input`}
                dropdownClass="register_drop_down_class"
                containerClass="regsiter_contaitner_class"
                value={values.phone}
                country='us'
                onFocus={()=>this.handleLabelFocus('phone')}
                onBlur={()=>this.handleLabelFocus()}
                inputStyle={touched.phoneNumber && errors.phoneNumber ? {border:"1px solid #F87171"}: {}}
                onChange={(value, data:{dialCode:string}, _event, _formattedValue)=>{
                  const countryCode = data.dialCode;
                  const phoneNumber = countryCode && value.substring(countryCode.length);
                  setFieldValue("phone",value);
                  setFieldValue("phoneNumber",phoneNumber);
                  setFieldValue("countryCode",countryCode);
                }}
                placeholder="Your Phone Number"
              />
              {touched.phoneNumber && (
          <Box className="login_valid_error_new">
            {errors.phoneNumber}
          </Box>
        )}
            </Box>
    
            <Box>
              <CustomLabel isFocused={focusLabel === 'departure'}>Preferred Departure Airport</CustomLabel>
              <CustomDepartueAiportAutoCompplete
                id="preferredAirport"
                onChange={(_event:React.ChangeEvent<HTMLInputElement>, newValue:string) => {
                  setFieldValue('preferredAirport', newValue);
                }}
                isFocusedTypeInput={focusLabel === 'departure'}
                bgColor={'black'}
                onFocus={()=>this.handleLabelFocus('departure')}
                onBlur={()=>this.handleLabelFocus()}
                open={isAutcompleteMenuOpen}
                PopperComponent={CustomStyledPopper}
                ListboxProps={{ id: "regsiter_option_style" }}
                onOpen={() => {
                  this.handleMenuOpenClose(true);
                }}
                onClose={() => {
                  this.handleMenuOpenClose(false);
                }}
                getOptionLabel={(option: {name:string}) => option.name}
                options={options}
                renderOption={(_option: unknown, { selected }:{selected:boolean}) => (
                  <>{this.renderPrefferedOption(selected)}</>
                )}
                loading={isDataLoading}
                renderInput={(params:TextFieldProps) => (
                  <CustomFormTextField
                  className={touched.preferredAirport && errors.preferredAirport ? "register_border_error" : ""}
                    {...params}
                    placeholder="Start typing to search..."
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isDataLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {(params.InputProps)!.endAdornment}
                        </React.Fragment>
                      ),
                      startAdornment: (
                        <Box className="register_grey_font">
                          <SearchOutlined />
                        </Box>
                      ),
                    }}
                  />
                )}
              />
              {touched.preferredAirport && (
          <Box className="login_valid_error_new">
            {errors.preferredAirport}
          </Box>
        )}
            </Box>
          </Box>
          <FormSubmitButton
             onClick={(event: React.MouseEvent<HTMLButtonElement>)=>{handleSubmit(event as unknown as React.FormEvent<HTMLFormElement> | undefined)}}
            id="regsiter_test_submit_button"
            style={{
              margin: "1rem 0rem",
              marginTop: isAutcompleteMenuOpen ? "12rem" : "",
            }}
            fullWidth
            type="submit"
          >
            Complete Profile
          </FormSubmitButton>
          </form>)}
        </Formik>
      </Box>
    );
  };

  renderPrefferedOption = (isSelected:boolean)=>{
    return <Box className={`register_airport_option`}>
          <Box color="#636E72"><RoomOutlined/></Box>
          <Box>Alabama, Birmingham - KBHM</Box>
    </Box>
  }
  
  renderEmailTextField = (
    errors: FormikErrors<valuesType>,
    touched: FormikTouched<valuesType>,
    handleChange: handleChange,
  ) => {
    const {focusLabel} = this.state;
    return (
      <Box>
        <CustomLabel isFocused={focusLabel === 'email'}>Email</CustomLabel>
        <CustomFormTextField
          autoComplete="off"
          className={touched.email && errors.email ? "register_border_error" : ""}
          id="register_test_email"
          name="email"
          isFocusedTypeInput={true}
          variant='outlined'
          onChange={handleChange}
          onFocus={()=>this.handleLabelFocus('email')}
          onBlur={()=>this.handleLabelFocus()}
          size="medium"
          placeholder="Your Email"
          fullWidth
        />
        {touched.email && (
          <Box className={"login_valid_error_new"}>{errors.email}</Box>
        )}
      </Box>
    );
  };
  
  renderPassword = (
    errors: FormikErrors<valuesType>,
    touched: FormikTouched<valuesType>,
    handleChange: handleChange,
    _setFieldTouched: (field: keyof valuesType, isTouched?: boolean, shouldValidate?: boolean) => void,
  ) => {
    const {focusLabel} = this.state;
    return (
      <Box>
        <CustomLabel isFocused={focusLabel === 'password'}>Password</CustomLabel>
        <CustomFormTextField
          onFocus={() => {
            this.handleIsPasswordErrorsVisible(true);
            this.handleLabelFocus('password');
          }}
          isFocusedTypeInput={true}
          variant='outlined'
          size="medium"
          fullWidth
          autoComplete="off"
          name="password"
          className={`register_test_password ${
            touched.password && errors.password ? "register_border_error" : ""
          }`}
          placeholder="Your Password"
          type={this.state.isPasswordVisible ? "text" : "password"}
          onChange={(event:React.FormEvent<HTMLInputElement>) => {
            handleChange(event);
          }}
          onBlur={()=>this.handleLabelFocus()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    this.handlePasswordVisible("isPasswordVisible");
                  }}
                  className="password_button_type"
                >
                  {!this.state.isPasswordVisible ? (
                    <VisibilityOff style={{ color: colors.GRAY_COLOR_FORM }} />
                  ) : (
                    <Visibility style={{ color: colors.GRAY_COLOR_FORM }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
         {touched.password && errors.password && (
          <Box className="login_valid_error_new">
            Invalid Password*
          </Box>
        )}
      </Box>
    );
  };
  
  renderConfirmPassword = (
    errors: FormikErrors<valuesType>,
    touched: FormikTouched<valuesType>,
    handleChange: handleChange,
  ) => {
    const {focusLabel,isConfirmPasswordVisible} = this.state;
    return (
      <Box>
        <CustomLabel isFocused={focusLabel === 'confirm'}> Confirm Password</CustomLabel>
        <CustomFormTextField
          name="password_confirmation"
          autoComplete="off"
          onChange={handleChange}
          isFocusedTypeInput={true}
          variant='outlined'
          onBlur={()=>this.handleLabelFocus()}
          onFocus={() => {this.handleLabelFocus('confirm')}}
          size="medium"
          placeholder="Confirm Password"
          fullWidth
          className={`register_test_confirm_password ${
            touched.password_confirmation && errors.password_confirmation
              ? "register_border_error"
              : ""
          }`}
          type={isConfirmPasswordVisible ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    this.handlePasswordVisible("isConfirmPasswordVisible");
                  }}
                  className="password_button_type"
                >
                  {!isConfirmPasswordVisible ? (
                    <VisibilityOff style={{ color: colors.GRAY_COLOR_FORM }} />
                  ) : (
                    <Visibility style={{ color: colors.GRAY_COLOR_FORM }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {touched.password_confirmation && (
          <Box className="login_valid_error_new">
            {errors.password_confirmation}
          </Box>
        )}
      </Box>
    );
  };
  
  renderPasswordTester = (
    password: string,
    _passwordTouched: boolean,
    condition: boolean,
  ) => {
    if (password && condition) {
      return <CheckRounded className="register_valid_tick" />;
    }
    if (this.state.isPasswordErrorsVisible  && !password) {
      return <CheckRounded className="register_valid_grey" />;
    }
    return <ClearOutlined className="register_valid_cross" />;
  };
  
  renderPasswordErrorContainer = (
    touched: FormikTouched<valuesType>,
    values: valuesType,
  ) => {
    return (
      <>
        {this.state.isPasswordErrorsVisible && (
      <Box style={{marginTop:"1.2rem",padding:'0'}} className="register_password_validation_container">
          <>
            <Box>Password must contain:</Box>
            <Box className="regsiter_display_flex">
              <Box component={"span"} className="register_icon_container">
                {this.renderPasswordTester(
                  values.password,
                  touched.password as boolean,
                  /[A-Z]/.test(values.password),
                )}
              </Box>
              At least one capital letter{" "}
            </Box>
            <Box className="regsiter_display_flex">
              <Box component={"span"} className="register_icon_container">
                {this.renderPasswordTester(
                  values.password,
                  touched.password as boolean,
                  /[a-z]/.test(values.password),
                )}
              </Box>
              At least one lowercase letter
            </Box>
            <Box className="regsiter_display_flex">
              <Box component={"span"} className="register_icon_container">
                {this.renderPasswordTester(
                  values.password,
                  touched.password as boolean,
                  /\d/.test(values.password),
                )}
              </Box>
              At least one number
            </Box>
            <Box className="regsiter_display_flex">
                      <Box component={"span"} className="register_icon_container">
                        {this.renderPasswordTester(
                          values.password,
                          touched.password as boolean,
                          /[!@#$%^&*(),.?":{}|<>]/.test(values.password),
                        )}
                      </Box>
                      At least one special character
                    </Box>
            <Box className="regsiter_display_flex">
              <Box component={"span"} className="register_icon_container">
                {this.renderPasswordTester(
                  values.password,
                  touched.password as boolean,
                  values.password.length >= 8,
                )}
              </Box>
              Minimum character length is 8 characters
            </Box>
          </>
      </Box>
        )}
      </>
    );
  };
  
  renderTermsAndConditions = (handleChange: handleChange,termsTouched:boolean,termsError:string) => {
    return (
      <Box>
           <CustomFormCheckbox
          className="regsiter_test_checkbox"
          name={"terms_and_conditions_accepted"}
          onChange={handleChange}
          style={{marginRight:'10px',paddingLeft:'0px'}}
        />
            <FormControlLabel
      className="regsiter_test_checkbox_parent"
      control={<div />}
      label={
    <Box>
      I agree with the <Box className="hoverCursor" component={"u"} onClick={()=>this.handleNavigationToTerms('policy')}>Privacy</Box> &{" "}
      <Box className="hoverCursor" component={"u"} onClick={()=>this.handleNavigationToTerms('terms')}>Terms</Box> Policies
    </Box>
  }
/>
      {termsTouched && (
          <Box className={"login_valid_error_new"} marginTop={'-10px'}>{termsError}</Box>
        )}
      </Box>
    );
  };
  
  renderRegistrationForm = () => {
    return (
      <Formik
        innerRef={(formikProps) => {
          this.formikMethods = formikProps;
        }}
        initialValues={{
          email: "",
          password: "",
          password_confirmation: "",
          terms_and_conditions_accepted: false,
        }}
        onSubmit={(values) => {
          this.createNewAccount(values);
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          setFieldTouched,
        }) => (
          <form style={{marginBottom:'0'}}>
            <Box className={"register_form_parent"}>
              <Box className={"register_form_head"}>Register</Box>
              <Box className={"register_form_container"}>
                {this.renderEmailTextField(errors, touched, handleChange)}
                {this.renderPassword(
                  errors,
                  touched,
                  handleChange,
                  setFieldTouched
                )}
                {this.renderConfirmPassword(errors, touched, handleChange)}
                {this.renderPasswordErrorContainer(touched, values)}
                <Box marginTop={'1rem'}>{this.renderTermsAndConditions(handleChange,touched.terms_and_conditions_accepted as boolean, errors.terms_and_conditions_accepted as string)}</Box>
                <FormSubmitButton
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    this.handleIsPasswordErrorsVisible(true);
                    handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>);
                  }}
                  id="regsiter_test_submit_button"
                  style={{ margin: "1rem 0rem"}}
                  fullWidth
                  className={
                    this.isCreateAccountGrey(
                      values.password,
                      values.password_confirmation,
                      values.email,
                    )
                      ? ""
                      : "regsiter_account_grey"
                  }
                >
                  Create Account
                </FormSubmitButton>
                <Box className="register_form_register_btn">
                  <CustomLabel>Already Have an account?</CustomLabel>
                  <ForgotPasswordTypeButton id="regsiter_test_login_button" onClick={this.handleNavigationToLogin}>Log In</ForgotPasswordTypeButton>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    );
  };
  

    


  // Customizable Area End

  render() {
    // Customizable Area Start
    const {isCreateProfileFormVisible}  = this.state;
    return (
      <>
        { !isCreateProfileFormVisible ? this.renderRegistrationForm() : this.renderCreateProfileForm()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End