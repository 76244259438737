import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { DebitSkyCardIcon, DebitSkyCardSmallerIcon, SkyCardDebitCardIcon, SkyCardIcon} from "../../../components/src/assets";
import { AddPaymentMethodModal } from "../../../components/src/ReUsableModal";
import { ButtonBox,PaymentCard, DFlexBetween, DFlexAlignCenter, GoldishSimpleButton, StyledBox, MuiModal, StatusModal, MuiResuableStatusModalBody, FormSubmitButton, SurityModal, SureModalButtonContainer, TopupBox, CustomFormTextField, Footer,} from "../../../components/src/MuiCustomComponents";
import {AirplaneSvg,deleteIcon, edit_icon} from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const skyCards = [
  {
    type:"Debit",
    remaining:"$12,300",
  },
  {
    type:"25 Hour",
    remaining:"12Hrs & 32Mins",
    aircraft:"Super Midsize Jet",
  }
]

const debitCards = [
  {
    type:"debit",
    cardholder_name:"Leslie Alexander",
    card_number:"5678"
  },
  {
    type:"Visa",
    cardholder_name:"Leslie Alexander",
    card_number:"5678"
  }
]

// Customizable Area End

import Cfpaymentgateway33Controller, {
  CardDetails,
  Props,
  configJSON,
} from "./Cfpaymentgateway33Controller";
import { FormikProps, FormikValues } from "formik";


export default class Cfpaymentgateway33 extends Cfpaymentgateway33Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPaymentMethods = ()=>{
    const {showPaymentModal,action,focusedLabel,cardIndex} = this.state
    return(
      <>
      <Box className="booking_section" style={{rowGap:"1.5rem"}}>
        {this.renderHeading("Payment Methods")}
        <Box className="profile_main_container" mb={0} pt={"1.5rem"}>
          {this.renderCardsWithTitle("Sky Cards")}
        </Box>
        <Box className="profile_main_container" mb={0} pt={"1.5rem"}>
          {this.renderCardsWithTitle("Debit/Credit Cards")}
          <ButtonBox mt={"2.2rem"} onClick={()=>this.openPaymentModal("add",-1)} id="add_debit_card">Add New Credit/Debit Card</ButtonBox>
        </Box>
      </Box>
      {showPaymentModal &&(
        <AddPaymentMethodModal
        showModal = {showPaymentModal}
        actionType={action}
        openPaymentModal = {this.openPaymentModal}
        cancel={this.closePaymentModal}
        focusedField={focusedLabel}
        focusField={this.handleFocus}
        {...(action !== "add" && cardIndex >= 0 ?{initialValues:this.getCardDetails()}:{})}
        deleteCard={()=>{this.handleSureModalOpen(true,cardIndex)}}
        isDeactivated={false}
        createCard={this.handleCreateNewCard}
        cardFormik={(formikProps: FormikProps<FormikValues>) => this.cardFormik = formikProps}
        makePrimaryPaymentMethod= {this.handleMakePrimaryMethod}
        />
      )}
      {this.renderPaymentUpdateModal()}
      {this.renderDeleteCardConfirmationModal()}
      </>
    )
  }

  renderSkyCards = ()=>{
   return <>
    <Box className="booking_section" style={{rowGap:"1.5rem"}}>
      {this.renderHeading("Debit Sky Card")}
      <Box className="profile_main_container" mb={0} pt={"1.5rem"}>
      {this.renderCards("sky card")}
      
      </Box>
    </Box>
    {this.renderFooter()}
    </>
  }

  renderHeading = (heading:string)=>{
    return(
      <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:'1.6rem'}}>{heading}</GoldishSimpleButton>
    )
  }

  renderCardsWithTitle = (title:string)=>{
    return(
      <>
      {this.renderSubHeading(title)}
      {title==="Sky Cards"?this.renderCards(title):this.renderDebitCards()}
                        
      </>
    )
    
  }

  renderSubHeading = (heading:string)=>{
    return(
      <Box style={{marginBottom:"1.5rem", color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:"1.5rem", fontWeight:600}}>{heading}</Box>

    )
  }

  renderCards = (cardType:string)=>{
    const {page} = this.state
    const isSkyCard = page === "/SkyCards"
    const cards = isSkyCard ? [skyCards[0]]:skyCards
    return <Grid container spacing={2}>
      {cards.map((card)=>this.renderSkyCard(card))}
      {page === "/SkyCards" && this.renderTopups()} 
    </Grid>
  }

  renderDebitCardsTopup = ()=>{
    return(
      <Grid container spacing={3}>
        {skyCards.map((card)=>this.renderSkyCard(card))}
        {this.renderTopups()}
      </Grid>
    )
  }

  renderTopups = ()=>{
    return <Grid item sm={12} md={6} lg={7} xl={8}>
      <TopupBox>
        <Box className="topup-heading">Add Balance to my Debit Sky Card</Box>
        <Box className="topup-subheading">Choose from predefined amount or add a custom value</Box>
        <Box className="topupBox">
          {this.renderCustomAmountBox()}
          {this.renderTopupBox()}
          {this.renderTopupBox()}
        </Box>
        </TopupBox>      
    </Grid>
  }

  renderTopupBox = ()=>{
    return <Box className="topup">
      <Box className="amount">$ 50,000</Box>
      <Button className="buy-btn">Buy Now</Button>
    </Box>
  }

  renderCustomAmountBox = ()=>{
  return <Box className="custom-amount topup">
      <CustomFormTextField 
      height={"3.5rem"} 
      style={{maxWidth:"60%"}} 
      fullWidth 
      placeholder="Enter custom amount" 
      client={true}></CustomFormTextField>
      <Button className="buy-btn">Buy Now</Button>
    </Box>
  }

  renderSkyCard = (card:any)=>{
    const isDebit = card.type === "Debit"
    const cardIconContainerStyle = isDebit ? "card_img debitskyCard":"sky_card card_img"
    return(
      <Grid item sm={12} md={6} lg={5} xl={4}>
        <StyledBox>
          <Grid container className="card_parent">
            <Grid item xs={8} className="card_content">
              {isDebit ?this.renderDebitCardContent(card):this.renderSkyCardContent(card)}
            </Grid>
            <Grid item xs={4} className={cardIconContainerStyle}>{ isDebit?<DebitSkyCardIcon/>:<SkyCardIcon/>}</Grid>
          </Grid>
        </StyledBox>        
      </Grid>
    )
  }

  renderSkyCardContent = (card:any)=>{
    const {type,remaining,aircraft} = card
    return <Box className="card_container">
      <Box className="view_button_parent" gridColumnGap={1}>
        <Box><SkyCardDebitCardIcon /></Box>
        <Box className="card_heading" color={"#fff"}>{type} Sky Card</Box>
      </Box>

      <Box className="booking_section" style={{color:"#fff",rowGap: "0.1rem" }}>
        <Box display={"flex"} justifyContent={"center"} gridColumnGap={"0.5rem"} mb={0.5}>
        <Box display={"flex"} alignItems={"center"}><AirplaneSvg/></Box>
        <Box className="card_name">{aircraft}</Box>
        </Box>
        
        <Box fontSize={"1rem"} color={"#cbd5e1"}>Remaining</Box>
        <Box fontSize={"1.4rem"}>{remaining}</Box>
      </Box>
    </Box>
  }

  renderDebitCardContent=(card:any)=>{
    const {type,remaining} = card
    return <Box className="card_container">
      <Box className="view_button_parent" style={{ columnGap: '1rem' }}>
        <Box><DebitSkyCardSmallerIcon /></Box>
        <Box className="card_heading" color={"#fff"} >{type} Sky Card</Box>
      </Box>

      <Box className="booking_section" style={{ rowGap: "0.3rem", color: "#fff" }}>
        <Box className="card_content_bottom" style={{ justifyContent: 'flex-start' }}>
          <Box className="view_button_parent" style={{ marginTop: '-4px', color: "#cdd5e1" }}>
            <Box className="view_debit_card" component={'span'}>Remaining</Box>
          </Box>

        </Box>
        <Box fontSize={"1.4rem"}>{remaining}</Box>
      </Box>
    </Box>
  }

  renderDebitCards = ()=>{
    const {cards} = this.state
    return<Grid container spacing={2}>
      {cards.map((card,index)=>this.renderDebitCard(card,index))}
    </Grid>
  }

  renderDebitCard = (card:CardDetails,index:number)=>{
    const {cards:{card_type,card_number,card_holder_name},primary_method} = card.attributes
    return <Grid item sm={12} md={6} lg={4} xl={3}>
      <PaymentCard onClick={()=>this.openPaymentModal("show",index)} id="debit_card"> 
          <DFlexBetween>
          <div className="card-btns">
          <div className="div-img"><img  src={this.handleCardProvider(card_type)} alt="" height={"28px"} width={"44px"} /></div>
          {primary_method && <button className="card-btn">Primary</button>}
          </div>
          <DFlexAlignCenter>
          <>
              <Box px={"0.5rem"} id="delete_icon" onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                  event.stopPropagation()
                  this.handleSureModalOpen(true, index)
                }}>
                <img src={deleteIcon} alt="" className="pointer delCardModal"/>
              </Box>
              <Box px={"0.5rem"} id="edit_icon" onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                  event.stopPropagation()
                  this.openPaymentModal("edit", index)
                }}>
                <img src={edit_icon} alt="" className="pointer"/>
              </Box>
            </>                                 
          </DFlexAlignCenter>
          </DFlexBetween>                    
          <Box className="card-name">{card_holder_name}</Box>
          <p className="card-info"> **** **** **** {card_number}</p>
        </PaymentCard>
    </Grid>
  } 
  
  renderPaymentUpdateModal = ()=>{
    const {action} = this.state
    const isEditAction = action === "edit"
    const subHeading = isEditAction? "You can access the revised payment method at checkout": "You can now access your payment method at checkout"
      return <MuiModal
      open={this.state.isStatusModalVisible}
    >
      <StatusModal className="status_update_modal" payment={true}>
      <MuiResuableStatusModalBody
        heading = {`Payment Method ${isEditAction ? "Edited":"Added"} Successfully`}
        subHeading = {subHeading}
      >
        <FormSubmitButton onClick={()=>this.handleStatusVisible(false)} fullWidth className="okay_button">Back to Payments</FormSubmitButton>
      </MuiResuableStatusModalBody>
                    
      </StatusModal>
    </MuiModal>

  }

  renderDeleteCardConfirmationModal=()=>{
    const {isSureModalOpen,cardIndex} = this.state
  return <SurityModal isOpen={isSureModalOpen}><SureModalButtonContainer submit={this.handleDeleteCard} cancel={()=>this.handleSureModalOpen(false,cardIndex)}/></SurityModal>
  }

  renderFooter = ()=>{
    return<Footer>
      <Box className="profile_submission">
        <Box display={"flex"} gridColumnGap={"3rem"} alignItems={"center"} width={"100%"}>
        <Box>
          <Box color={"#94a3b8"} fontSize={"1.2rem"}>Amount Selected</Box>
          <Box color={"#fff"} fontSize={"1.4rem"} fontWeight={700}>$ 0.0</Box>
        </Box>
        <FormSubmitButton style={{ color: "white", background: "#3E4247",padding:"2rem 5rem" }}>Checkout</FormSubmitButton>
        </Box>
        
        
      </Box>
    </Footer>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      this.state.page === "/SkyCards" ? this.renderSkyCards() : this.renderPaymentMethods()
      // this.renderPaymentMethods()
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
